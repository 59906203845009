import { Expression } from './expression';
import { ConnectorId } from './connector';
import { DateTime } from './schedule';
import { BucketFieldType } from './schema-definition';
import { IdentifierFormatType } from '@neptune/services/contact.service';
import { ExpressionDialogOutput } from '@neptune/components/expression-editor/expression-dialog';
import { SelectedField } from 'app/main/content/pages/message/message-key-selector-picker/message-key-selector-picker.component';

/**
 * Table status
 */
// eslint-disable-next-line no-shadow
export enum TableStatus {
  DRAFT = 'DRAFT',
  COMMITTED = 'COMMITTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

// eslint-disable-next-line no-shadow
export enum TableIndexStatus {
  COMPLETED = 'COMPLETED'
}

// eslint-disable-next-line no-shadow
export enum DialogOutputStatus {
  COMPLETE,
  CANCEL
}

// eslint-disable-next-line no-shadow
export enum JoinType {
  ERROR = 'error',
  IN_PROGRESS = 'in-progress',
  COMMON = 'common'
}

export interface NameId {
  name: string;
  id?: string;
}

export interface TableChoiceDetails {
  name: string;
  id?: string;
  type?: string;
  icon?: string;
}

export interface JoinData {
  columns: ColumnPair[];
  fromTable: string;
  toTable: string;
  type?: JoinType;
}

export interface ColumnPair {
  from: string;
  to: string;
}
export interface CanvasPositionResponse {
  PositionInfo: CanvasPositionData;
}
export interface CanvasPositionData {
  [tableName: string]: TablePositionData;
}

export interface TablePositionData {
  top: number;
  left: number;
}

export interface TableCommonData {
  id: string;
  name: string;
  columns: Field[];
  fields: Field[];
}

export interface TableDetails {
  id: string;
  name: string;
  tableStatus: TableStatus;
  tableStatusClass?: string;
  updatedAt: string;
  columns: TableColumnDetails[];
  countStats?: string;
  scheduleId?: string;
  taskId?: string;
  stateDateOfLastIngest?: string;
  stateDateOfLastIngestTime?: DateTime;
  stats?: {
    maxStarid: number;
    lastIngest: string; // TODO: is there a Date.ISO type?
    recordCount: number;
  };
  relationships?: JoinData;
  contactProfiles?: string[];
  system?: boolean;
  editable?: boolean;
  type?: TableDataType;
  updatedBy?: string;
  updatedByUser?: SchemaUser;
}

export interface TableColumnDetails {
  id?: string;
  primaryKey?: boolean;
  dataType?: DataType;
  name: string;
  selected?: boolean;
  disabled?: boolean;
  format?: any;
  formatType?: IdentifierFormatType;
  editable?: boolean;
  contactProfile?: string;
  position?: number;
  joined?: TableFieldJoinedLabel;
  readOnly?: boolean;
  calculationType?: FieldCalculationType;
  transformation?: Transformation;
  transformationId?: string;
  nullable?: boolean;
  size?: number;
  fieldType?: SchemaFieldType;
  type?: string;
}

// eslint-disable-next-line no-shadow
export enum SchemaFieldType {
  STATIC = 'STATIC',
  CALCULATED = 'CALCULATED',
  SYSTEM = 'SYSTEM' // don't set
}

// eslint-disable-next-line no-shadow
export enum SchemaFieldFormat {
  NONE = 'None'
}

export class FileUploadModel {
  data: any;
  name: string;
  fileName?: string;
  type: string;
  size: number;
  sizeText: string;
  customName: string;
  id: string;
  delimiter: string;
  qualifier: string;
  header: boolean;
  key?: string;
}

export class FileUpload {
  dataSource: string[][] = [];
  columnNames: string[] = [];
  format: FileFormat;
}

export interface UploadConfig {
  connectorId: ConnectorId | null;
  instanceId: string | null;
  directoryPath: string;
}

export class FileFormat {
  delimiter: DelimiterType | string | null;
  qualifier: QualifierType | string | null;
  hasHeaderRow: boolean;
  rowDelimiter?: RowDelimiterType | string | null;
  fileType?: FileType | null;
  qualifierType?: QualifierType | null;
}

// eslint-disable-next-line no-shadow
export enum FileType {
  TEXT = 'txt',
  JSON = 'json'
}

interface SchemaUser {
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
}

export interface SchemaObject {
  Id?: string;
  TableIndexStatus?: string;
  fields: SchemaField[];
  index?: SchemaIndex[];
  insertedAt?: string;
  insertedBy?: string;
  insertedByUser?: SchemaUser;
  key: string[];
  name: string;
  service?: string;
  tableStatus: TableStatus;
  type?: TableDataType;
  updatedAt?: string;
  updatedBy?: string;
  updatedByUser?: SchemaUser;
  version: number;
  scheduleId?: string;
  taskId?: string;
  contactProfiles?: string[];
  childOrgId?: string;
  containsAddress?: boolean;
  filePattern?: string;
}

export interface SchemaField {
  size: number;
  nullable: boolean;
  name: string;
  position: number | null;
  type: DataTypeJDBC;
  transformationId?: string;
  fieldType?: SchemaFieldType;
  visible?: boolean;
  format?: string | null;
  formatType?: IdentifierFormatType | null;
  isPrimaryKey?: boolean;
  readonly?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
}

interface SchemaIndex {
  name: string;
  fields: string[];
  type: string;
}

export interface TableDefinition {
  name: string;
  columns?: Field[] | null;
  primaryKeys?: boolean[];
}

export interface SourceTargetTables {
  sourceTable: TableDefinition;
  targetTable: TableDefinition;
  sampleData?: { [key: string]: any }[];
}

export interface UploadFileInfo {
  fileId: string;
  name: string;
  uploaded: boolean;
}

export interface Field {
  name: string;
  dataType: DataType;
  isPrimaryKey?: boolean;
  editable?: boolean;
  calculationType?: FieldCalculationType;
  transformation?: Transformation;
  transformationId?: string;
  format?: string;
  contactProfile?: string;
  position?: number;
  size?: number;
  nullable?: boolean;
  fieldType?: SchemaFieldType;
  visible?: boolean;
  description?: string;
  joined?: TableFieldJoinedLabel;
  readOnly?: boolean;
}

export interface TableData {
  Id: string;
  fields: TableField[];
  index: number[];
  key: string[];
  name: string;
  tableStatus: TableStatus;
  taskId?: string;
  scheduleId?: string;
  version: number;
  updatedAt?: string;
  updatedBy?: string;
  stats?: TableStatsDetails;
  TableIndexStatus?: string;
  relationships?: any;
  system?: boolean;
  editable?: boolean;
  type?: TableDataType;
  sisenseOid?: string;
  description?: string;
  filePattern?: string;
}

// eslint-disable-next-line no-shadow
export enum TableDataType {
  TABLE = 'TABLE',
  LIST = 'LIST'
}

// eslint-disable-next-line no-shadow
export enum TableDataSortOrderType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface TableStatsDetails {
  recordCount: number;
  maxStarId: number;
  lastIngest: string;
}

export interface TableJoinsData {
  [tableName: string]: { from: string; to: string; baseTable: string; fromTableName: string; toTableName: string }[];
}

export interface TablesJoins {
  message: TableJoinsData[] | string;
}

// eslint-disable-next-line no-shadow
export enum TableFieldJoinedLabel {
  JOINED = 'Joined',
  NOT_JOINED = ''
}

/**
 * Table data coming from backend
 */
export interface TableField {
  name: string;
  nullable: boolean;
  size: number;
  type: DataTypeJDBC;
  visible: boolean;
  position: number;
  format?: string;
  fieldType?: SchemaFieldType;
  join?: ColumnPair;
  fromTable?: string;
  toTable?: string;
  joined?: TableFieldJoinedLabel;
  transformationId?: string;
  transformation?: Transformation;
  disabled?: boolean;
}

export interface TableFieldTrend {
  fieldName: string;
  fieldValue: string;
  count: number;
}

export interface TableFieldMetrics {
  average: number;
  maximum: number;
  minimum: number;
  nullCount: number;
  rowCount: number;
  valueCount: number;
}

// eslint-disable-next-line no-shadow
export enum TableFieldMetric {
  AVERAGE = 'average',
  MAXIMUM = 'maximum',
  MINIMUM = 'minimum',
  NULL_COUNT = 'nullCount',
  ROW_COUNT = 'rowCount',
  VALUE_COUNT = 'valueCount'
}

export interface TableRowData {
  rows: TableSingleRowData[];
  totalCount?: number;
}
export interface TableSingleRowData {
  [key: string]: string;
}

export interface BucketField {
  bucketName: string;
  minValue?: number;
  maxValue?: number;
  values?: string[];
}

// eslint-disable-next-line no-shadow
export enum FieldCalculationType {
  NONE = 'none',
  FORMULA = 'formula', // Not used for 2019 May MVP
  AGGREGATE = 'aggregate',
  BUCKET = 'bucket',
  RANKED = 'ranked'
}

export interface IdentifierFieldType {
  name: string;
  value: string;
  autoSelect?: boolean | null;
}

export class FieldCalculationUtil {
  static FieldTypeMap: Map<FieldCalculationType, string> = new Map<FieldCalculationType, string>([
    [FieldCalculationType.NONE, 'None'],
    [FieldCalculationType.FORMULA, 'Formula'],
    [FieldCalculationType.AGGREGATE, 'Aggregate Function'],
    [FieldCalculationType.BUCKET, 'Bucket Field'],
    [FieldCalculationType.RANKED, 'Ranked Field']
  ]);
}

// eslint-disable-next-line no-shadow
export enum AggregateTransformFunctions {
  sum = 'sum',
  avg = 'avg',
  count = 'count',
  max = 'max',
  min = 'min',
  range = 'range'
}

export enum GeoAggregateTransformFunctions {
  distance = 'Distance calculation'
}

export interface Transformation {
  id?: string;
  destinationTableId: string;
  fieldId: string;
  transformDefinition: TransformDefinition;
  hidden?: boolean;
  active?: boolean;
  upsert?: boolean;
}

export interface TransformDefinition {
  dataType?: DataType;
  aggregate?: AggregationDefinition;
  formula?: ExpressionDialogOutput;
  values?: Record<string, unknown>[];
  function?: AggregateTransformFunctions;
  type: FieldCalculationType;
  value?: string;
  sourceField?: string;
  buckets?: BucketFieldType[];
  ranked?: RankedTransformDefinition;
}

export interface RankedTransformDefinition {
  rankAggColumnName: string;
  aggColumnName: string;
  detailColumn: string;
  aggTableId: string;
  rankedBy: string;
  rankedById?: string;
  rank: string;
  aggColumn: string;
  detailTableId: string;
  rankSortOrder: string;
  aggFunction: string;
  valueColumn?: string;
  outputColumnName?: string;
  outputColumnId?: string;
  baseColumn?: boolean;
}
export interface AggregationDefinition {
  function: AggregateTransformFunctions;
  tableId: string;
  fieldId: string;
  filters: AggregationFilter[];
  selectedField?: SelectedField;
}

export interface AggregationFilter {
  tableId?: string;
  fieldId?: string;
  operator?: AggregateOperator;
  valueDef?: AggregateLiteralValueDefinition[] | AggregateFunctionValueDefinition[];
  tableName?: string;
  fieldType?: DataTypeJDBC;
}

// eslint-disable-next-line no-shadow
export enum AggregateOperator {
  eq = 'eq',
  not_eq = 'not_eq',
  gt = 'gt',
  ge = 'ge',
  lt = 'lt',
  le = 'le',
  bt = 'bt',
  bw = 'bw',
  not_bw = 'not_bw',
  ew = 'ew',
  not_ew = 'not_ew',
  ct = 'ct',
  not_ct = 'not_ct'
}

export interface AggregateLiteralValueDefinition {
  valueType: 'literal';
  dataType?: DataType;
  filterValue?: string;
  text?: string;
}

export interface AggregateFunctionValueDefinition {
  valueType: 'expression';
  filterValue: Expression;
  text?: string;
}

// Field Types and conversion

/**
 * JDBC Data Types
 * see: https://docs.oracle.com/cd/E11882_01/java.112/e16548/apxref.htm#JJDBC28905
 * Tinytext type was added to fix issue loading files with more than 61 columns
 */
// eslint-disable-next-line no-shadow
export enum DataTypeJDBC {
  VARCHAR = 'VARCHAR',
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
  INTEGER = 'BIGINT',
  BIT = 'BIT',
  FLOAT = 'DOUBLE',
  TIMESTAMP = 'TIMESTAMP',
  DATETIME = 'DATETIME',
  TINYTEXT = 'TINYTEXT'
}

export enum GeoDataType {
  GEOLOCATION = 'GEOLOCATION'
}

// eslint-disable-next-line no-shadow
export enum DataType {
  ANY = 'any',
  STRING = 'string',
  TEXT = 'text',
  INTEGER = 'bigint',
  NUMERIC = 'numeric',
  DATE = 'date',
  DATETIME = 'datetime',
  TIMESTAMP = 'timestamp',
  TIME = 'time',
  BOOLEAN = 'boolean',
  NULL = 'null',
  UNKNOWN = 'unknown'
}

// eslint-disable-next-line no-shadow
export enum DataTypeQuery {
  NUMBER = 'number',
  STRING = 'string'
}

// eslint-disable-next-line no-shadow
export enum QualifierType {
  NONE = 'NONE',
  DOUBLE_QUOTE = 'DOUBLE_QUOTE',
  CUSTOM = 'CUSTOM'
}

// eslint-disable-next-line no-shadow
export enum RowDelimiterType {
  LF = 'LF',
  CRLF = 'CRLF'
}

// eslint-disable-next-line no-shadow
export enum DelimiterType {
  TAB = 'TAB',
  COMMA = 'COMMA',
  PIPE = 'PIPE',
  CUSTOM = 'CUSTOM',
  JSON = 'JSON'
}

export interface Delimiter {
  type: DelimiterType;
  name: string;
  message: string;
  value: string;
  isCustom: boolean;
}

export interface RowDelimiter {
  type: RowDelimiterType;
  name: string;
  value: string;
}

export class FileParsingOptionsUtils {
  static qualifiersMap = new Map<string, QualifierType>([
    ['', QualifierType.NONE],
    ['"', QualifierType.DOUBLE_QUOTE]
  ]);

  static delimitersMap = new Map<string, DelimiterType>([
    [',', DelimiterType.COMMA],
    ['|', DelimiterType.PIPE],
    ['\t', DelimiterType.TAB]
  ]);

  static rowDelimitersMap = new Map<string, RowDelimiterType>([
    ['\n', RowDelimiterType.LF],
    ['\r\n', RowDelimiterType.CRLF]
  ]);
}

// eslint-disable-next-line no-shadow
export enum DataImportStep {
  TABLE = 'table',
  CHOOSE = 'choose',
  CONNECT = 'connect',
  CHOOSE_FILE = 'chooseFile',
  SET_FILE_PARAMS = 'setFileParams',
  SOURCE_TABLE = 'sourceTable',
  LOCAL_SOURCE_TABLE = 'localSourceTable',
  FILTER = 'filter',
  PROFILE = 'profile',
  SCHEMA_DEFINITION = 'schemaDefinition'
}

// Utility class to help with type conversion
export class DataTypeUtils {
  static StepperIndexLocalTablesMap = new Map<number, DataImportStep>([
    [0, DataImportStep.TABLE],
    [1, DataImportStep.CHOOSE],
    [2, DataImportStep.FILTER],
    [3, DataImportStep.SCHEMA_DEFINITION],
    [4, DataImportStep.PROFILE]
  ]);

  static StepperIndexConnectMap = new Map<number, DataImportStep>([
    [0, DataImportStep.TABLE],
    [1, DataImportStep.CHOOSE],
    [2, DataImportStep.CONNECT],
    [3, DataImportStep.SOURCE_TABLE],
    [4, DataImportStep.FILTER],
    [5, DataImportStep.SCHEMA_DEFINITION],
    [6, DataImportStep.PROFILE]
  ]);

  static StepperIndexMyFilesMap = new Map<number, DataImportStep>([
    [0, DataImportStep.TABLE],
    [1, DataImportStep.CHOOSE],
    [2, DataImportStep.SET_FILE_PARAMS],
    [3, DataImportStep.FILTER],
    [4, DataImportStep.SCHEMA_DEFINITION],
    [5, DataImportStep.PROFILE]
  ]);

  static FieldMetricsMap = new Map<string, string>([
    [TableFieldMetric.AVERAGE, 'Average'],
    [TableFieldMetric.MAXIMUM, 'Maximum'],
    [TableFieldMetric.MINIMUM, 'Minimum'],
    [TableFieldMetric.NULL_COUNT, 'Null Count'],
    [TableFieldMetric.ROW_COUNT, 'Row Count'],
    [TableFieldMetric.VALUE_COUNT, 'Value Count']
  ]);

  static FieldMetricsNumericTypeTooltipMap = new Map<string, string>([
    [TableFieldMetric.AVERAGE, 'Average value of records within the selected column'],
    [TableFieldMetric.MAXIMUM, 'Maximum value of records within the selected column'],
    [TableFieldMetric.MINIMUM, 'Minimum value of records within the selected column'],
    [TableFieldMetric.NULL_COUNT, 'Total number of records with an empty value within the selected column'],
    [TableFieldMetric.ROW_COUNT, 'Total number of records within the selected column'],
    [TableFieldMetric.VALUE_COUNT, 'Total value of records within the selected column']
  ]);

  static FieldMetricsStringTypeTooltipMap = new Map<string, string>([
    [TableFieldMetric.AVERAGE, 'Average string length of records within the selected column'],
    [TableFieldMetric.MAXIMUM, 'Longest string length of records within the selected column'],
    [TableFieldMetric.MINIMUM, 'Shortest string length of records within the selected column'],
    [TableFieldMetric.NULL_COUNT, 'Total number of records with an empty value within the selected column'],
    [TableFieldMetric.ROW_COUNT, 'Total number of records within the selected column'],
    [TableFieldMetric.VALUE_COUNT, 'Total value of records within the selected column']
  ]);

  static FieldMetricsDateTypeTooltipMap = new Map<string, string>([
    [TableFieldMetric.AVERAGE, 'Average date of records within the selected column'],
    [TableFieldMetric.MAXIMUM, 'Maximum date of records within the selected column'],
    [TableFieldMetric.MINIMUM, 'Minimum date of records within the selected column'],
    [TableFieldMetric.NULL_COUNT, 'Total number of records with an empty value within the selected column'],
    [TableFieldMetric.ROW_COUNT, 'Total number of records within the selected column'],
    [TableFieldMetric.VALUE_COUNT, 'Total value of records within the selected column']
  ]);

  static DataTypeMap = new Map<DataType, string>([
    [DataType.STRING, 'Short Text (255 characters)'],
    [DataType.TEXT, 'Long Text'],
    [DataType.INTEGER, 'Whole number'],
    [DataType.NUMERIC, 'Decimal'],
    [DataType.DATE, 'Date'],
    [DataType.DATETIME, 'Date & Time'],
    [DataType.TIMESTAMP, 'Timestamp'],
    [DataType.BOOLEAN, 'Boolean']
  ]);

  static TableDataTypeMap = new Map<TableDataType, string>([
    [TableDataType.LIST, 'List'],
    [TableDataType.TABLE, 'Table']
  ]);

  static TableDataTypeSingularMap = new Map<string, string>([
    [ConnectorId.LISTS, 'List'],
    [ConnectorId.TABLES, 'Table']
  ]);

  static TableDataTypePluralMap = new Map<string, string>([
    [TableDataType.LIST, 'Lists'],
    [TableDataType.TABLE, 'Tables']
  ]);

  static DataTypeJDBCMap = new Map<DataTypeJDBC, string>([
    [DataTypeJDBC.VARCHAR, 'string'],
    [DataTypeJDBC.TEXT, 'string'],
    [DataTypeJDBC.NUMERIC, 'number'],
    [DataTypeJDBC.DATE, 'date'],
    [DataTypeJDBC.INTEGER, 'number'],
    [DataTypeJDBC.BIT, 'boolean'],
    [DataTypeJDBC.FLOAT, 'number'],
    [DataTypeJDBC.TIMESTAMP, 'date'],
    [DataTypeJDBC.DATETIME, 'date'],
    [DataTypeJDBC.TINYTEXT, 'string']
  ]);

  static fieldMetricToString(metric: string): string {
    return DataTypeUtils.FieldMetricsMap.get(metric) as string;
  }

  static fieldMetricTooltip(metric: string, fieldType: DataTypeJDBC): string {
    switch (fieldType) {
      case DataTypeJDBC.NUMERIC:
      case DataTypeJDBC.FLOAT:
      case DataTypeJDBC.INTEGER:
        return DataTypeUtils.FieldMetricsNumericTypeTooltipMap.get(metric) as string;
        break;
      case DataTypeJDBC.VARCHAR:
      case DataTypeJDBC.TEXT:
      case DataTypeJDBC.BIT:
      case DataTypeJDBC.TINYTEXT:
        return DataTypeUtils.FieldMetricsStringTypeTooltipMap.get(metric) as string;
        break;
      case DataTypeJDBC.DATETIME:
      case DataTypeJDBC.DATE:
      case DataTypeJDBC.TIMESTAMP:
        return DataTypeUtils.FieldMetricsDateTypeTooltipMap.get(metric) as string;
        break;
    }
  }

  static tableDataTypeToString(type: TableDataType): string {
    return DataTypeUtils.TableDataTypeMap.get(type) as string;
  }

  static dataTypeToString(type: DataType): string {
    return DataTypeUtils.DataTypeMap.get(type) as string;
  }

  /**
   * Conforms strings to standard data type
   */
  static toDataType(dt: string): DataType | undefined {
    dt = dt.toLowerCase();
    if (dt.includes('varchar') || dt.includes('string') || dt.includes('tinytext')) {
      return DataType.STRING;
    }
    if (dt.includes('text')) {
      return DataType.TEXT;
    }
    if (dt.includes('int')) {
      return DataType.INTEGER;
    }
    if (
      dt.includes('float') ||
      dt.includes('double') ||
      dt.includes('numeric') ||
      dt.includes('decimal') ||
      dt.includes('real')
    ) {
      return DataType.NUMERIC;
    }
    if (dt.includes('datetime')) {
      return DataType.DATETIME;
    }
    if (dt.includes('date')) {
      return DataType.DATE;
    }
    if (dt.includes('timestamp')) {
      return DataType.TIMESTAMP;
    }
    if (dt.includes('bit') || dt.includes('boolean')) {
      return DataType.BOOLEAN;
    }
  }

  static toDataTypeJDBC(dt: string): DataTypeJDBC | undefined {
    dt = dt.toLowerCase();
    if (dt.includes('string')) {
      return DataTypeJDBC.VARCHAR;
    }
    if (dt.includes('text')) {
      return DataTypeJDBC.TEXT;
    }
    if (dt.includes('int')) {
      return DataTypeJDBC.INTEGER;
    }
    if (dt.includes('numeric')) {
      return DataTypeJDBC.FLOAT;
    }
    if (dt.includes('datetime')) {
      return DataTypeJDBC.DATETIME;
    }
    if (dt.includes('date')) {
      return DataTypeJDBC.DATE;
    }
    if (dt.includes('timestamp')) {
      return DataTypeJDBC.TIMESTAMP;
    }
    if (dt.includes('boolean')) {
      return DataTypeJDBC.BIT;
    }
    if (dt.includes('tinytext')) {
      return DataTypeJDBC.TINYTEXT;
    }
  }
}

export const FILE_MASK_OPTIONS = ['d', 'dd', 'ddd', 'm', 'mm', 'mmm', 'yy', 'yyyy', 'hh', 'mi', 'ss'];
export const FILE_MASK_OPTIONS_HINTS = ['4', '04', 'Sun', '2', '02', 'Feb', '20', '2020', '13', '48', '45'];
export const EXPORTS_FILENAME_VALID_EXTENSION = ['csv', 'txt'];
export const MY_FILES_LABEL = 'My Files';
