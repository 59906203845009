import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteSyntaxInputComponent } from '@neptune/components/autocomplete-syntax-input/autocomplete-syntax-input.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from '@neptune/material.module';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [AutocompleteSyntaxInputComponent],
  imports: [CommonModule, FuseSharedModule, MaterialModule, ClipboardModule],
  exports: [AutocompleteSyntaxInputComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AutocompleteSyntaxInputModule {}
