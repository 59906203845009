import * as moment from 'moment';
import { TimezoneUtils } from '@neptune/models/timezone';
import { environment } from 'environments/environment.default';
import { RunState, SchedulerResponse } from './task';

// eslint-disable-next-line no-shadow
export const enum ScheduleOptionType {
  SCHEDULE = 'SCHEDULE',
  NONE = 'NONE'
}

export type RecurType = ByHour | ByDay | ByWeek | ByMonth;

interface Recurrence {
  type: number; // Index in TimeType,
  every: number;
  end: EndType;
}

export type EndType = { type: EndTimeType.never } | EndDate | EndRepeat;

export interface EndRepeat {
  type: EndTimeType.repeat;
  repeatTotal: number;
}

export interface EndDate {
  type: EndTimeType.date;
  date: string;
}

export interface ByDay extends Recurrence {
  excludeWeekend: boolean;
}

export interface ByHour extends Recurrence {
  excludeWeekend: boolean;
}
export interface ByWeek extends Recurrence {
  days: number[];
}

export interface ByMonth extends Recurrence {
  // TODO :: will need to force into monthly constraints
  day?: number;
  dayOccurrence?: DayOccurrence;
}

// eslint-disable-next-line no-shadow
export enum Day {
  Sun = 'Sun',
  Mon = 'Mon',
  Tues = 'Tue',
  Wed = 'Wed',
  Thurs = 'Thu',
  Fri = 'Fri',
  Sat = 'Sat'
}

// eslint-disable-next-line no-shadow
export enum Occurrence {
  none = 'none',
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
  last = 'last'
}

// eslint-disable-next-line no-shadow
export enum TimeType {
  day = 'day',
  week = 'week',
  month = 'month',
  hour = 'hour'
}

// eslint-disable-next-line no-shadow
export enum RepeatsEverySingularType {
  day = 'Daily',
  week = 'Weekly',
  month = 'Monthly',
  hour = 'Hourly'
}

// eslint-disable-next-line no-shadow
export enum RepeatsEveryPluralType {
  day = 'days',
  week = 'weeks',
  month = 'months',
  hour = 'hours'
}

// eslint-disable-next-line no-shadow
export enum EndTimeType {
  never = 1,
  date,
  repeat
}

export enum SchedulerEndType {
  never = 'never',
  date = 'date',
}

export interface DayOccurrence {
  weekOfTheMonth: number; // Index of Occurrence;
  day?: number; // Index in Day
}

/**
 * Object containing date and time.
 * Date is in MM/DD/YYYY format
 * Time is in 12 hr am/pm format
 */
export interface DateTime {
  date: string;
  time?: string;
}

/**
 * DateTime format required for formControl values
 */
export interface DateTimeForForm {
  date: moment.Moment;
  time?: string;
}

export interface RecurMessage {
  recurMessage: string;
  endMessage: string;
  recurDetailMessage?: string;
}

export type ScheduleDataType = OneTimeData | RecurData;

/**
 * Class to define import data
 */
export class Schedule {
  /** Unique identifier */
  id?: string = '';
  /** Name of the import, also used as the identifier so it needs to be unique */
  name: string = '';
  /** Flag for whether enabled or not */
  active: boolean = true;
  /** Data specific to schedule */
  data?: ScheduleDataType;
  /** Last or current run time */
  lastRun?: DateTime;
  /** Next scheduled run time */
  nextRun?: DateTime;
  Schedule?: ScheduleDataType;
}

class ScheduleData {
  /**
   * Prepopulate start date with today's date and time
   * If undefined, in one-time import scenario, start import job immediately
   */
  startDateTime?: DateTime;
  /** Contains string messages, for use in UI only */
  recurMessage?: RecurMessage;
}

export class OneTimeData extends ScheduleData {
  startImmediately?: boolean;
}

export class RecurData extends ScheduleData {
  recurData: RecurType;
  /** Next import date, if one-time this will be empty */
  nextImport?: DateTime;
}

/**
 * Model of schedule as defined by API
 */
export interface ScheduleModel {
  Active: boolean;
  Schedule: ScheduleDataModel;
  NextRun: string;
  Expiration: string;
  Id: string;
  LastRun: string;
  Name: string;
}

/**
 * Model of schedule as defined by API
 */
interface ScheduleDataModel {
  StartDateTime?: string;
  StartImmediately?: boolean;
  RecurData?: RecurModel;
}

/**
 * Model of recur data as defined by API
 */
interface RecurModel {
  ExcludeWeekend: boolean;
  Type: 1 | 2 | 3 | 4; // numeric enum, 1 = day, 2 = week, 3 = month, 4 = hour
  Days: number[]; // Index in Day Model.
  Every: number;
  Day: number;
  End: EndModel;
  WeekOfTheMonth?: number; // Index in Ocurrences
  DayOfWeek?: number;
}

interface EndModel {
  Type: EndTimeType;
  Occurrence?: number;
  Date?: string;
}

export class TimeUtils {
  /**
   * Get time string from moment
   */
  public static getTimeFromMoment(m: moment.Moment): string | null {
    if (m) {
      return TimezoneUtils.createMoment(m, 'hh:mm a').format('HH:mm');
    }
    return null;
  }

  /**
   * Make moment from time string
   */
  public static makeTimeMoment(time: string): moment.Moment {
    return TimezoneUtils.createMoment(time, 'hh:mm a');
  }

  /**
   * Format time string
   */
  public static formatTime(time: string): string {
    return TimeUtils.getTimeFromMoment(TimeUtils.makeTimeMoment(time)) as string;
  }

  /**
   * Assign time values to moment
   */
  public static assignTime(time: string, m?: moment.Moment): moment.Moment {
    const newTime = TimeUtils.makeTimeMoment(time);
    if (m) {
      m.set('hour', newTime.get('hour'));
      m.set('minute', newTime.get('minute'));
      return m;
    } else {
      return newTime;
    }
  }

  /**
   * Assign time values from moment
   */
  // public static assignTimeMoment(time: moment.Moment, m?: moment.Moment): moment.Moment {
  //   if (m) {
  //     m.set('hour', time.get('hour'));
  //     m.set('minute', time.get('minute'));
  //     return m;
  //   } else {
  //     return time;
  //   }
  // }

  /**
   * Assign date values from moment, but retain existing time values
   */
  public static assignDateMoment(date: moment.Moment, m?: moment.Moment, endOfDay: boolean = false): moment.Moment {
    if (m) {
      const time = TimeUtils.getTimeFromMoment(m) as string;
      const dateNumber = date.get('date');
      m.set('date', dateNumber);
      TimeUtils.assignTime(time as string, m);
      return m;
    } else {
      // if end of day force to end of day, beginning of day is default
      if (endOfDay) {
        return this.assignTime('23:59', date);
      }
      return date;
    }
  }
}

export class ScheduleUtils {
  // ////////////// CONVERSION ////////////////

  public static convertTimeTypeToIndex(tt: TimeType): number {
    return Object.values(TimeType).indexOf(tt);
  }

  public static convertIndexToTimeType(ttIndex: number): TimeType {
    return Object.values(TimeType)[ttIndex];
  }

  public static convertIndexToOccurrence(occurrenceIndex: number): Occurrence {
    return Object.values(Occurrence)[occurrenceIndex];
  }

  /**
   * Converter for schedule model
   */
  public static convertScheduleModel(model: ScheduleModel): Schedule {
    const _schedule: Schedule = <Schedule>{
      id: model.Id,
      name: model.Name,
      active: model.Active,
      data: ScheduleUtils.convertScheduleDataModel(model.Schedule)
    };
    if (model.LastRun) {
      _schedule.lastRun = TimezoneUtils.utcToDateTime(model.LastRun);
    }
    if (model.NextRun && new Date(model.NextRun).getTime() > Date.now()) {
      _schedule.nextRun = TimezoneUtils.utcToDateTime(model.NextRun);
    }
    return _schedule;
  }

  public static convertScheduleModelV2(response: SchedulerResponse): Schedule {
    return <Schedule>{
      id: response.Id,
      name: response.name,
      active: response.status === RunState.ACTIVE,
      data: ScheduleUtils.convertScheduleDataModelV2(response)
    };
  }

  /**
   * Converter for schedule to API model
   */
  public static convertSchedule(data: Schedule): ScheduleModel {
    return <ScheduleModel>{
      Active: data.active,
      Name: data.name,
      Schedule: ScheduleUtils.convertScheduleData((data.data || data.Schedule) as ScheduleDataType)
    };
  }

  // ////////////// FACTORY ////////////////

  /**
   * Get today's date with a military time of 21:04
   */
  public static getDateToday(offsetDay: number = 0, offsetTime: number = 10): DateTime {
    return <DateTime>{
      date: TimezoneUtils.createMoment().add(offsetDay, 'day').format('MM/DD/YYYY'),
      // must use military time
      time: TimezoneUtils.getTimeWithOffset(offsetTime)
    };
  }

  /**
   * Create one time Schedule object
   */
  public static createOneTime(offsetDay: number = 0, offsetTime: number = 10): Schedule {
    const schedule: Schedule = new Schedule();
    schedule.data = ScheduleUtils.createOneTimeData(offsetDay, offsetTime);
    return schedule;
  }

  /**
   * Create Recurring new Schedule Object
   */
  public static createEmptyRecurring(): Schedule {
    const schedule: Schedule = <Schedule>{
      id: '',
      name: '',
      active: true,
      data: <ScheduleDataType>{
        startDateTime: ScheduleUtils.getDateToday(0, environment.defaultScheduleTimeOffset),
        recurData: <ByDay>{
          type: ScheduleUtils.convertTimeTypeToIndex(TimeType.day),
          excludeWeekend: true,
          every: 1,
          end: <EndType>{
            type: EndTimeType.never
          }
        }
      }
    };

    return schedule;
  }

  /**
   * Create one time data object
   */
  public static createOneTimeData(offsetDay: number = 0, offsetTime: number = 10): OneTimeData {
    return <OneTimeData>{
      startDateTime: ScheduleUtils.getDateToday(offsetDay, offsetTime),
      startImmediately: true
    };
  }

  // ////////////// MESSAGE ////////////////

  public static recurMessage(data: ScheduleData): string {
    let message: string = '';
    if (!ScheduleUtils.isRecurring(data)) {
      return 'One-time';
    } else {
      switch (data.recurData.type) {
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.day):
          message = ScheduleUtils.repeatsEveryMessage(
            data.recurData.every,
            RepeatsEveryPluralType.day,
            RepeatsEverySingularType.day
          );
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.week):
          message = ScheduleUtils.repeatsEveryMessage(
            data.recurData.every,
            RepeatsEveryPluralType.week,
            RepeatsEverySingularType.week
          );
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.month):
          message = ScheduleUtils.repeatsEveryMessage(
            data.recurData.every,
            RepeatsEveryPluralType.month,
            RepeatsEverySingularType.month
          );
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.hour):
          message = ScheduleUtils.repeatsEveryMessage(
            data.recurData.every,
            RepeatsEveryPluralType.hour,
            RepeatsEverySingularType.hour
          );
          break;
        default:
          console.warn('Invalid end type');
      }
    }
    // add space at
    return message;
  }

  private static repeatsEveryMessage(recurData: number, pluralType: string, singularType: string) {
    return recurData > 1 ? `Every ${recurData} ${pluralType}` : singularType;
  }

  public static recurDetailMessage(data: ScheduleData): string {
    let message: string = '';
    if (ScheduleUtils.isRecurring(data)) {
      switch (data.recurData.type) {
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.day):
          message = (<ByDay>data.recurData).excludeWeekend ? 'excluding weekends' : 'including weekends';
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.week):
          const days = ([] as number[]).concat((<ByWeek>data.recurData).days).sort();
          if (days && days.length > 0) {
            const length = days.length;
            message = `on ${ScheduleUtils.dayIdxToString(days[0], days.length > 1)}`;
            if (length > 1) {
              for (let i = 1; i < days.length; i++) {
                if (i === length - 1) {
                  message += ` & ${ScheduleUtils.dayIdxToString(days[i], true)}`;
                } else {
                  message += `, ${ScheduleUtils.dayIdxToString(days[i], true)}`;
                }
              }
            }
          }
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.month):
          if (typeof (<ByMonth>data.recurData).day === 'number') {
            // TODO :: deal with nth, yuck...
            message = `on the ${(<ByMonth>data.recurData).day}th`;
          } else if ((<ByMonth>data.recurData).dayOccurrence) {
            const dayOccur: DayOccurrence = (<ByMonth>data.recurData).dayOccurrence as DayOccurrence;

            if (!dayOccur.day) {
              message = 'on last day of month';
            } else {
              message = `on the ${ScheduleUtils.convertIndexToOccurrence(
                dayOccur.weekOfTheMonth
              )} ${ScheduleUtils.dayIdxToString(dayOccur.day)}`;
            }
          } else {
            message = 'Schedule Data Error';
          }
          break;
        case ScheduleUtils.convertTimeTypeToIndex(TimeType.hour):
          message = (<ByHour>data.recurData).excludeWeekend ? 'excluding weekends' : 'including weekends';
          break;
        default:
          console.warn('Invalid end type');
      }
    }
    return message;
  }

  public static endMessage(data: ScheduleData): string {
    let message: string = '';
    if (!ScheduleUtils.isRecurring(data)) {
      if (data.startDateTime) {
        message += 'On ' + data.startDateTime.date;
      } else {
        message += 'Start Immediately';
      }
    } else {
      message += 'Ends ';
      // check if date
      if (data.recurData.end.type === EndTimeType.date) {
        message += `on ${data.recurData.end.date}`;
      }
      // check if repeat
      else if (data.recurData.end.type === EndTimeType.repeat) {
        const total: number = data.recurData.end.repeatTotal;
        message += `after ${total} `;
        message += total === 1 ? `ocurrence` : `ocurrences`;
      }
      // else assume 'never'
      else {
        message += 'never';
      }
    }
    return message;
  }

  public static fullMessage(schedule: Schedule): string {
    let message = ScheduleUtils.recurMessage(schedule.data as ScheduleDataType);
    if (ScheduleUtils.isRecurring(schedule.data as ScheduleDataType)) {
      message += `, ` + ScheduleUtils.recurDetailMessage(schedule.data as ScheduleDataType);
    } else {
      message += `, ` + ScheduleUtils.endMessage(schedule.data as ScheduleDataType);
    }
    return message;
  }

  // ////////////// CASTING ////////////////

  /**
   * Method to cast schedule data type to RecurData
   */
  public static isRecurring(data: OneTimeData | RecurData): data is RecurData {
    return (<RecurData>data).recurData !== undefined;
  }

  /**
   * Method to cast schedule data type to OneTimeData
   */
  public static isOneTime(data: OneTimeData | RecurData): data is OneTimeData {
    return (<RecurData>data).recurData === undefined;
  }

  // ////////// PRIVATE ////////////

  private static dayIdxToString(dayIdx: number, abbreviate: boolean = false): string {
    const theDay: Day = Object.values(Day)[dayIdx];
    if (abbreviate) {
      return theDay;
    }
    switch (theDay) {
      case Day.Mon:
        return 'Monday';
      case Day.Tues:
        return 'Tuesday';
      case Day.Wed:
        return 'Wednesday';
      case Day.Thurs:
        return 'Thursday';
      case Day.Fri:
        return 'Friday';
      case Day.Sat:
        return 'Saturday';
      case Day.Sun:
        return 'Sunday';
      default:
        console.warn(`Invlaid day enum ${dayIdx}`);
        return '';
    }
  }

  private static convertScheduleDataModel(model: ScheduleDataModel): ScheduleDataType {
    if (model.RecurData) {
      return <RecurData>{
        startDateTime: TimezoneUtils.utcToDateTime(model.StartDateTime as string),
        recurData: ScheduleUtils.convertRecurModel(model.RecurData)
      };
    } else {
      if (model.StartImmediately) {
        return <OneTimeData>{
          startImmediately: true
        };
      } else {
        return <OneTimeData>{
          startDateTime: TimezoneUtils.utcToDateTime(model.StartDateTime as string)
        };
      }
    }
  }

  // Converts Schedule Data from Scheduler-API Model
  private static convertScheduleDataModelV2(response: SchedulerResponse): ScheduleDataType {
    if (!response.recurType) {
        return <OneTimeData>{
          startDateTime: response.startImmediately
            ? undefined
            : TimezoneUtils.utcToDateTime(response.awsSchedule.StartDate),
          startImmediately: response.startImmediately,
        };
    }

    return <RecurData>{
      startDateTime: TimezoneUtils.utcToDateTime(response.awsSchedule.StartDate),
      recurData: ScheduleUtils.convertRecurModelV2(response),
    };
  }

  private static convertRecurModel(model: RecurModel): RecurType | null {
    switch (model.Type) {
      case 1:
        return <ByDay>{
          type: ScheduleUtils.convertTimeTypeToIndex(TimeType.day),
          every: model.Every,
          end: ScheduleUtils.convertEndModel(model.End),
          excludeWeekend: model.ExcludeWeekend
        };
      case 2:
        return <ByWeek>{
          type: ScheduleUtils.convertTimeTypeToIndex(TimeType.week),
          every: model.Every,
          end: ScheduleUtils.convertEndModel(model.End),
          days: model.Days
        };
      case 3:
        const res: ByMonth = <ByMonth>{
          type: ScheduleUtils.convertTimeTypeToIndex(TimeType.month),
          every: model.Every,
          end: ScheduleUtils.convertEndModel(model.End),
          day: model.Day
        };

        if (model.WeekOfTheMonth !== undefined) {
          res.dayOccurrence = <DayOccurrence>{
            weekOfTheMonth: model.WeekOfTheMonth,
            day: model.DayOfWeek
          };
        } else if (typeof model.Day !== 'number') {
          console.warn('For now only have support for dya of month');
        }
        return res;
      case 4:
        return <ByHour>{
          type: ScheduleUtils.convertTimeTypeToIndex(TimeType.hour),
          every: model.Every,
          end: ScheduleUtils.convertEndModel(model.End),
          excludeWeekend: model.ExcludeWeekend
        };
      default:
        console.warn(`Unknown schedule time type ${model.Type}`);
        return null;
    }
  }

  // Converts Schedule Recurring Data from Scheduler-API Model
  private static convertRecurModelV2(response: SchedulerResponse): RecurType | null {
    if (response.recurType === TimeType.hour) {
      return <ByHour> {
        type: ScheduleUtils.convertTimeTypeToIndex(TimeType.hour),
        every: response.every,
        end: ScheduleUtils.convertEndModelV2(response),
        excludeWeekend: response.excludeWeekend,

      };
    } else if (response.recurType === TimeType.day) {
      return <ByDay> {
        type: ScheduleUtils.convertTimeTypeToIndex(TimeType.day),
        every: response.every,
        end: ScheduleUtils.convertEndModelV2(response),
        excludeWeekend: response.excludeWeekend,
      };
    } else if(response.recurType === TimeType.week) {
      return <ByWeek> {
        type: ScheduleUtils.convertTimeTypeToIndex(TimeType.week),
        days: response.daysOfWeek.split(',').map((day) => parseInt(day, 10)),
        every: response.every,
        end: ScheduleUtils.convertEndModelV2(response),
      };
    } else if (response.recurType === TimeType.month) {
      const isDayOccurance = response.weekOfMonth;
      const dayOccurrence: DayOccurrence = {
        weekOfTheMonth: response.weekOfMonth,
        day: response.daysOfWeek ? parseInt(response.daysOfWeek, 10) : undefined,
      };

      return <ByMonth> {
        type: ScheduleUtils.convertTimeTypeToIndex(TimeType.month),
        day: response.dayOfMonth,
        dayOccurrence: isDayOccurance ? dayOccurrence : undefined,
        every: response.every,
        end: ScheduleUtils.convertEndModelV2(response),
      };
    } else {
      return null;
    }
  }

  private static convertEndModel(model: EndModel): EndType | null {
    switch (model.Type) {
      case EndTimeType.never:
        return {
          type: EndTimeType.never
        };
      case EndTimeType.date:
        return <EndDate>{
          type: EndTimeType.date,
          date: model.Date
        };
      case EndTimeType.repeat:
        return <EndRepeat>{
          type: EndTimeType.repeat,
          repeatTotal: model.Occurrence
        };
      default:
        console.warn(`Unknown end time type ${model.Type}`);
        return null;
    }
  }

  // Converts Schedule End Data from Scheduler-API Model
  private static convertEndModelV2(response: SchedulerResponse): EndType | null {
    if (response.endType === SchedulerEndType.date) {
      return <EndDate>{
        type: EndTimeType.date,
        date: moment(response.awsSchedule.EndDate).format('MM/DD/YYYY'),
      };
    }

    return {
      type: EndTimeType.never
    };
  }

  private static convertScheduleData(data: ScheduleDataType): ScheduleDataModel | void {
    if (ScheduleUtils.isOneTime(data)) {
      if (data.startDateTime) {
        return <ScheduleDataModel>{
          StartDateTime: TimezoneUtils.dateTimeToUTCString(data.startDateTime)
        };
      } else {
        return <ScheduleDataModel>{
          StartImmediately: true,
          StartDateTime: moment().utc().format()
        };
      }
    } else if (ScheduleUtils.isRecurring(data)) {
      return <ScheduleDataModel>{
        StartDateTime: TimezoneUtils.dateTimeToUTCString(data.startDateTime as DateTime),
        RecurData: ScheduleUtils.convertRecur(data.recurData)
      };
    }
  }

  private static convertRecur(data: RecurType): RecurModel | null {
    switch (data.type) {
      case ScheduleUtils.convertTimeTypeToIndex(TimeType.day):
        return <RecurModel>{
          Type: 1,
          Every: data.every,
          End: ScheduleUtils.convertEnd(data.end),
          ExcludeWeekend: (<ByDay>data).excludeWeekend
        };
      case ScheduleUtils.convertTimeTypeToIndex(TimeType.week):
        return <RecurModel>{
          Type: 2,
          Every: data.every,
          End: ScheduleUtils.convertEnd(data.end),
          Days: (<ByWeek>data).days
        };
      case ScheduleUtils.convertTimeTypeToIndex(TimeType.month):
        if (typeof (<ByMonth>data).day !== 'number') {
          console.warn('For now only have support for day of month');
        }
        const res: RecurModel = <RecurModel>{
          Type: 3,
          Every: data.every,
          End: ScheduleUtils.convertEnd(data.end),
          Day: (<ByMonth>data).day
        };
        if ((<ByMonth>data).dayOccurrence && (<ByMonth>data).dayOccurrence?.weekOfTheMonth !== undefined) {
          res.WeekOfTheMonth = (<ByMonth>data).dayOccurrence?.weekOfTheMonth;
          res.DayOfWeek = (<ByMonth>data).dayOccurrence?.day;
        }
        return res;
      case ScheduleUtils.convertTimeTypeToIndex(TimeType.hour):
        return <RecurModel>{
          Type: 4,
          Every: data.every,
          End: ScheduleUtils.convertEnd(data.end),
          ExcludeWeekend: (<ByHour>data).excludeWeekend
        };
      default:
        console.warn(`Unknown recur type`);
        return null;
    }
  }

  private static convertEnd(data: EndType): EndModel | null {
    switch (data.type) {
      case EndTimeType.never:
        return <EndModel>{
          Type: EndTimeType.never
        };
      case EndTimeType.date:
        return <EndModel>{
          Type: EndTimeType.date,
          Date: data.date
        };
      case EndTimeType.repeat:
        return <EndModel>{
          Type: EndTimeType.repeat,
          Occurrence: data.repeatTotal
        };
      default:
        console.warn(`Unknown end time type`);
        return null;
    }
  }
}

export interface ScheduleDialogData {
  id: string;
  name: string;
  active: boolean;
  lastRun: {
    date: string;
    time: string;
  };
  schedule: {
    data: {
      startDateTime: {
        date: string;
      };
      startImmediately: boolean;
    };
  };
  data: {
    startDateTime: {
      date: string;
    };
    startImmediately: boolean;
  };
}
