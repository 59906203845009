import { NgModule } from '@angular/core';
import { KeySelectorComponent } from './key-selector.component';
import { MaterialModule } from '@neptune/material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { AutocompleteSyntaxInputModule } from '@neptune/components/autocomplete-syntax-input/autocomplete-syntax-input.module';

@NgModule({
  declarations: [KeySelectorComponent],
  imports: [FuseSharedModule, MaterialModule, AutocompleteSyntaxInputModule],
  exports: [KeySelectorComponent]
})
export class KeySelectorModule {}
