import { TableData } from './file';
import { MyFile } from './my-files';
/**
 * Types of input fields for connector options
 */
export type InputType = 'text' | 'password' | 'textarea';

/**
 * Types of data sources
 */
// eslint-disable-next-line no-shadow
export enum ConnectorType {
  upload,
  connect,
  localTable,
  childOrg
}

// eslint-disable-next-line no-shadow
export enum ConnectorId {
  FTP = 'FTP',
  SFTP = 'SFTP',
  MyFiles = 'MyFiles',
  S3 = 'S3',
  TABLES = 'Tables',
  LISTS = 'Lists'
}

/**
 * Types of data sources
 */
// eslint-disable-next-line no-shadow
export enum ConnectorStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

/**
 * Types of data sources
 */
// eslint-disable-next-line no-shadow
export enum ConnectorDirection {
  IN = 'IN',
  OUT = 'OUT',
  BOTH = 'BOTH'
}

/**
 * Interface describing values required for data source icons
 */
export interface Icon {
  type: IconType;
  value: string; // TODO :: this may vary?
}

export type IconType = 'svg' | 'mdi';

export interface Connector {
  id: string;
  options: ConnectorOption[];
  title?: string;
  count?: number;
  status?: ConnectorStatus;
  direction?: ConnectorDirection;
  oauth?: boolean;
  pollable?: boolean;
}

export const SNOWFLAKE_CONNECTOR = 'Snowflake';

/**
 * Interface for input fields within a source connection form
 */
export interface ConnectorOption {
  // key name for parameter sent to connector
  paramName: string;
  // type of input, if undefined defaults to 'text'
  type?: InputType;
  // eslint-disable-next-line @typescript-eslint/ban-types
  required?: Boolean;
  // Name to display for required parameter, is not provided will use param
  // TODO :: We will want this to be localized
  title?: string;
  public?: boolean;
  hidden?: boolean;
}

/**
 * Interface for external data sources, contains all info required to display and connect to an external data
 */
export interface ConnectorData {
  name: string;
  type: ConnectorType;
  title: string;
  icon: Icon;
  id?: string;
  connectorOptions?: ConnectorOption[];
  count?: number;
  status?: ConnectorStatus;
  direction?: ConnectorDirection;
  oauth?: boolean;
}

export interface ConnectorParam {
  connectorID?: string;
  parameters: Record<string, unknown>; // Object of strings
  internal?: boolean;
  pollable?: boolean;
}

export interface UploadConnectorParam extends ConnectorParam {
  parameters: {
    FileID: string;
  };
}

export interface SecretObject {
  instance_id: string;
  FileID?: string;
  message?: string;
}

export interface ConnectorInstanceSet {
  connectorId: string;
  instances: ConnectorInstance[];
}

export interface ConnectorInstance {
  connectorType: string;
  instanceId: string;
  lastUpdated: string;
  publicField: [
    | {
        URI: string;
      }
    | {
        User: string;
      }
  ];
  secretName: string;
  orgConnector?: string;
  pollable?: boolean;
}

export interface ConnectorInstanceFlat {
  index: number;
  connectorType: string;
  instanceId: string;
  lastUpdated: string;
  secretName: string;
  URI?: string;
  User?: string;
}

export interface ConnectorSetModel {
  connectors: ConnectorInstance[];
}

export interface FTPCredential {
  URI?: string;
  User?: string;
  id: number;
  lastUpdated: string;
  publicField: [
    | {
        URI: string;
      }
    | {
        User: string;
      }
  ];
  instanceId: string;
  connectorType: string;
  secretName: string;
  selected?: boolean;
}

export interface ConnectorSource {
  connectorData: ConnectorData;
  resource: MyFile | TableData | ConnectorData | SnowflakeTable;
}

export interface Databases {
  snowflakeDbs: string[];
}

export interface SnowflakeSchemas {
  snowflakeSchemas: string[];
}

export interface SnowflakeTables {
  Tables: SnowflakeTable[];
}

export interface SnowflakeTable {
  stats: {
    recordCount: number;
  };
  name: string;
  Id?: string;
  inserinsertedAt: string;
  updatedAt: string;
}

export interface SnowflakeTableSchema {
  nullable: boolean;
  editable: boolean;
  readOnly: boolean;
  comment: string;
  name: string;
  primaryKey: boolean;
  type: string;
}

export class ConnectorsUtils {
  public static isFileBaseConnector(connector: ConnectorId): boolean {
    return [ConnectorId.FTP, ConnectorId.MyFiles, ConnectorId.S3, ConnectorId.SFTP].indexOf(connector) >= 0;
  }
}
