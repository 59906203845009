import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Field, DataType } from '@neptune/models/file';

@Component({
  selector: 'app-key-selector',
  templateUrl: './key-selector.component.html',
  styleUrls: ['./key-selector.component.scss']
})
export class KeySelectorComponent implements OnInit {
  @Input() set fields(f: Field[]) {
    if (f === undefined) {
      f = [];
    }
    this._fields = f.filter(field => field.dataType !== DataType.TEXT);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    this.keyFields = this._fields.filter((f: Field) => f.isPrimaryKey);
  }
  @Input() disableSelect: boolean = false;
  @Output() keySelection = new EventEmitter<Field[]>();

  public keyFields: Field[] = [];
  public _fields: Field[];
  public fileMaskOptions = ['*', '?', 'd', 'dd', 'ddd', 'm', 'mm', 'mmm', 'yy', 'yyyy', 'hh', 'mi', 'ss'];
  public fileMaskOptionsHints = ['*', '?', '4', '04', 'Sun', '2', '02', 'Feb', '20', '2020', '13', '48', '45'];
  public fileForm: UntypedFormGroup;

  constructor(protected formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.fileForm = this.formBuilder.group({
      fileMask: ['']
    });
  }

  get filePattern(): string {
    const filePattern: string = this.fileForm.controls.fileMask.value;
    return filePattern;
  }

  public onSelected() {
    this.keyFields.forEach((f: Field) => (f.isPrimaryKey = true));
    this.keySelection.emit(this.keyFields);
  }
}
