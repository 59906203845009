import { DateTime, Schedule, ScheduleModel } from './schedule';
import { TaskCategory } from './expression';
import { NameId } from './file';
import { TimezoneUtils } from '@neptune/models/timezone';
import { UploadType } from '.';
// ///////////// TASK ///////////////

// eslint-disable-next-line no-shadow
export enum TaskType {
  UNDEFINED = 'UNDEFINED',
  JOURNEY = 'JOURNEY-EXECUTE',
  AGGREGATE = 'AGGREGATE',
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT'
}

export class Task {
  /** Unique identifier */
  id?: string;
  /** Name of the task, also used as the identifier so it needs to be unique */
  name?: string;
  /** Flag for whether enabled or not */
  active: boolean;
  /** Task type */
  type: TaskType;
  /** Time and status of latest job, which included a currently running job */
  lastRun?: RunData;
  /** Id of schedule */
  scheduleId?: string;
  /** Data specfic to the task */
  data: TaskData;
}

export interface RunResult {
  id: string;
  runId: string;
}

/**
 * Task type returned by API
 */
export interface TaskTypeModel {
  Active: true;
  Id: string;
  Name: TaskType;
}

/**
 * Task type
 */
export interface TaskTypeData {
  id: string;
  name: TaskType;
}

/** API formated data */
type TaskDataModelType = TaskDataModel;

export class TaskSchedule<T extends Task> {
  task: T;
  schedule?: Schedule;
  compressed?: boolean;
  filteredRows?: TaskSchedule<T>[];
  taskGroups?: TaskSchedule<T>[];
}

export interface SchedulerResponse {
  awsSchedule: {
    EndDate: string;
    StartDate: string;
  };
  dayOfMonth: number;
  daysOfWeek: string;
  endType: string;
  every: number;
  excludeWeekend: boolean;
  expression: string;
  expressionType: string;
  Id: string;
  input: {
    body: {
      messageBody: string;
    };
  };
  name: string;
  recurType: string;
  repeatTotal: number;
  startImmediately: boolean;
  status: RunState;
  TaskId: string;
  weekOfMonth: number;
}

/**
 * Model of task as defined by API
 */
interface TaskBaseModel {
  /* Unique id for use by table look up */
  Id: string;
  /* Reabable name of task */
  Name: string;
  /* Id specific to this task, TaskRuns associated with this task will share the same TaskId */
  TaskId: string;
  /* Used to differeniate between tasks and runs */
  TaskCategory: TaskCategory;
  /* Whether task is active or not, if inactive will not be added to queue */
  Active: true;
  /* Id for schedule */
  ScheduleId: string;
  /* Data for task, specific to TaskType */
  Data: TaskDataModelType;
  /* Id for task type, use to retrieve TaskType object */
  TaskTypeId?: string;
  RunState?: string;
  Schedule?: ScheduleModel;
  OrgId?: string;
  RunId?: string;
}

/**
 * Model of task as defined by API
 */
export interface TaskModel extends TaskBaseModel {
  /* Used to differeniate between tasks and runs */
  TaskCategory: TaskCategory.TEMPLATE;
  LastJobStatus?: string;
  LastRun?: string;
}

export interface RunModel extends TaskBaseModel {
  TaskCategory: TaskCategory.RUN;
  InsertedAt: string;
}

interface TaskProfile extends NameId {
  destinationTableId?: string;
}

export class TaskData {
  /** Pipeline profile */
  profile: TaskProfile;
  fileName?: string;
  sourceTable?: TaskDataTable;
  targetTable?: TaskDataTable;
  postProcessOption?: PostProcessOptionData;
}

interface PostProcessOptionData {
  option: PostProcessOptionType | 'None';
  folderName?: string;
}

// eslint-disable-next-line no-shadow
export enum PostProcessOptionType {
  DELETE = 'delete',
  ARCHIVE = 'archive'
}

export interface TaskDataTable {
  id?: string;
  name?: string;
  source?: string;
  sourceParameters?: TaskDataSourceParameters;
}

interface TaskDataSourceParameters {
  fileId: string;
  instanceId: string;
  connectorId?: UploadType;
  fileName?: string;
  filePath?: string;
  schema?: SourceParametersSchema;
}
interface SourceParametersSchema {
  delimiter: string;
  fileType: string;
}

// ///////////// IMPORT TASK ///////////////
interface Table extends NameId {
  /** source of table */
  source: string;
}

interface QPTable extends Table {
  // target source will always be QuickPivot
  source: 'QuickPivot';
}

export class ImportTask extends Task {
  type: TaskType.IMPORT;
  data: ImportData;
}

/**
 * Basci information about import task, enough to retrive more specific data and display information in UI
 */
export class ImportData extends TaskData {
  /** Source of import */
  sourceTable: Table;
  /** Destination of import */
  targetTable: QPTable;
}

// ///////////// EXPORT TASK ///////////////

/**
 * Format of Import task object returned from API
 */
export interface TaskDataModel {
  profileId: string;
  destinationTableId: string;
  fileName?: string;
  runId?: string;
  postProcessOption?: PostProcessOptionData;
  tableName?: string;
}

export class ExportTask extends Task {
  type: TaskType.EXPORT;
  data: ExportData;
}

export class ExportData extends TaskData {
  /** Source of export */
  sourceTable: QPTable;
  /** Destination of export */
  targetTable: Table;
  /** Query info */
  query: NameId;
}

export class TaskUtils {
  public static readonly QP = 'QuickPivot';

  public static taskTypeMap: Map<TaskType, string> = new Map<TaskType, string>([
    [TaskType.AGGREGATE, 'Aggregate Field'],
    [TaskType.EXPORT, 'Export'],
    [TaskType.IMPORT, 'Import'],
    [TaskType.JOURNEY, 'Journey Execute'],
    [TaskType.UNDEFINED, 'Undefined']
  ]);

  public static getType(taskType: TaskType) {
    return TaskUtils.taskTypeMap.get(taskType);
  }

  public static convertTaskData(taskType: TaskType, taskDetail: TaskData): TaskDataModel | null {
    switch (taskType) {
      default:
        if (taskDetail && taskDetail.profile) {
          return <TaskDataModel>{
            profileId: taskDetail.profile.id,
            destinationTableId: taskDetail.profile.destinationTableId,
            fileName: taskDetail.fileName,
            postProcessOption: taskDetail.postProcessOption,
            tableName: taskDetail.targetTable?.name
          };
        } else {
          console.warn('TaskData not given or missing profile property');
          return null;
        }
    }
  }

  /**
   * Converter task API model to RunData
   */
  public static convertRunModel(model: RunModel): RunData {
    return <RunData>{
      id: model.RunId,
      dateTime: TimezoneUtils.utcToDateTime(model.InsertedAt),
      runState: model.RunState
    };
  }

  public static createImportTask(name: string = ''): ImportTask {
    return <ImportTask>{
      type: TaskType.IMPORT,
      name,
      active: true,
      data: {
        sourceTable: {
          name: '',
          source: ''
        },
        targetTable: {
          name: '',
          source: TaskUtils.QP
        },
        profile: {
          name: ''
        }
      }
    };
  }

  public static createExportTask(name: string = ''): ExportTask {
    return <ExportTask>{
      type: TaskType.EXPORT,
      name,
      active: true,
      data: {
        sourceTable: {
          name: '',
          source: TaskUtils.QP
        },
        targetTable: {
          name: '',
          source: ''
        },
        profile: {
          name: ''
        }
      }
    };
  }
}

// ///////////// RUNS ///////////////

export interface RunData {
  /** unique identifier */

  id: string;
  /** Date and time run started */

  dateTime: DateTime;
  /** Current state of the run */

  runState: RunState;
  /** How long the run took to either complete or fail */

  runTime?: string;
  /** Error messages associated with failure */

  error?: string;
}

// eslint-disable-next-line no-shadow
export enum RunState {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  DONE = 'DONE',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED'
}
