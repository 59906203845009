<input
  #container
  #syntaxInput
  matInput
  type="text"
  [disabled]="_disabled || (!!ngControl.disabled)"
  [placeholder]="placeholder"
  [(ngModel)]="_value"
  (change)="onChange()"
  (keydown)="onKeyDown($event)"
  (keyup)="onKeyUp($event)"
  (input)="onInput($event)"
  (click)="onClick($event)"
  [matAutocomplete]="auto"
  #trigger="matAutocompleteTrigger"
  [matAutocompleteDisabled]="autoCompleteDisabled"
  class="autocomplete-syntax-input {{ classes }}"
/>
<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="onOptionSelected($event)"
  (closed)="onAutocompleteClosed()"
  [autoActiveFirstOption]="true"
  class="syntax-autocomplete"
  [panelWidth]="autocompletePanelWidth"
>
  <mat-option *ngFor="let column of _options; let i = index" [value]="column">
    <div class="autocomplete-syntax-input__option-text" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="option-value">{{ column }}</div>
      <div *ngIf="_optionsHints[i]" class="option-hint">{{ _optionsHints[i] }}</div>
    </div>
  </mat-option>
</mat-autocomplete>
