import { of as observableOf, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  TableCreationStore,
  PipelineProfile,
  ImportSourceField,
  ProfileSourceParameters,
  ImportPaginationData
} from '@neptune/models/pipeline';
import { RunResult } from '@neptune/models/task';
import { ConnectorType } from '@neptune/models/connector';
import { TaskCategory, ExpressionField } from '@neptune/models/expression';
import { BaseService, Endpoint } from './base.service';
import { cloneDeep } from 'lodash';
import { DataType } from '@neptune/models/file';

@Injectable()
export class PipelineService extends BaseService {
  private PIPELINE_STORE: string = 'PIPELINE_STORE';
  private PREFIX: string = 'DataPipelines';

  /**
   * For use with jasmine testing, returns a mock of service
   */
  public static mockService(jasmine: any): any {
    return {
      getProfileStore: jest.fn(() => observableOf(new Map<string, PipelineProfile>()))
    };
  }

  /**
   * Creat PipelineProfile profile object required for APIs using data in store and provided expression fields
   */
  createProfileImport(
    expFields: ExpressionField[],
    filePattern,
    tableId: string,
    childOrgId?: string | undefined,
    sourceTableType?: string | undefined,
    sourceDatabase?: string | undefined,
    sourceSchema?: string | undefined
  ): PipelineProfile {
    const store: TableCreationStore = this.storeService.get(TableCreationStore.ID);
    // convert table fields to Source Fields
    const sourceFields: ImportSourceField[] = store.sourceFields.filter(
      field => field.included || (field.filter && field.filter.value !== '')
    );
    let parameters: ProfileSourceParameters = {
      instanceId: store.connectorInstanceId
    };
    parameters = childOrgId ? { ...parameters, childOrgId } : parameters;
    const profile: PipelineProfile = {
      name: store.targetName,
      filePattern,
      type: TaskCategory.TEMPLATE,
      destination: {
        name: store.targetName,
        destinationId: tableId,
        fields: expFields // this.etlStep.getExpressionFields()
      },
      source: {
        connectorId: (childOrgId ? sourceTableType : store.connector.id) as string,
        fields: sourceFields,
        parameters,
        object: store.connector.type === ConnectorType.upload ? store.uploadFile.name : store.sourceTableId,
        sourceDatabase: sourceDatabase ? sourceDatabase : undefined,
        sourceSchema: sourceSchema ? sourceSchema : undefined
      }
    };
    // if source is file upload add fileId property to parameters
    if (store.connector.type === ConnectorType.upload) {
      profile.source.parameters['fileId'] = store.uploadFile.fileId;
    }
    return profile;
  }

  /**
   * Creat PipelineProfile profile object required for APIs using data in store and provided expression fields
   */
  createProfile(
    expFields: ExpressionField[],
    tableId: string,
    childOrgId?: string | null,
    sourceTableType?: string | null
  ): PipelineProfile {
    const store: TableCreationStore = this.storeService.get(TableCreationStore.ID);
    // convert table fields to Source Fields
    const sourceFields: ImportSourceField[] = store.sourceFields.filter(
      field => field.included || (field.filter && field.filter.value !== '')
    );
    let parameters: ProfileSourceParameters = {
      instanceId: store.connectorInstanceId
    };
    parameters = childOrgId ? { ...parameters, childOrgId } : parameters;
    const profile: PipelineProfile = {
      name: store.targetName,
      type: TaskCategory.TEMPLATE,
      destination: {
        name: store.targetName,
        destinationId: tableId,
        fields: expFields // this.etlStep.getExpressionFields()
      },
      source: {
        connectorId: (childOrgId ? sourceTableType : store.connector.id) as string,
        fields: sourceFields,
        parameters,
        object: store.connector.type === ConnectorType.upload ? store.uploadFile.name : store.sourceTableId
      }
    };
    // if source is file upload add fileId property to parameters
    if (store.connector.type === ConnectorType.upload) {
      profile.source.parameters['fileId'] = store.uploadFile.fileId;
    }
    return profile;
  }

  /**
   * Save pipeline profile and add to cache, returns pipeline with an id assigned
   */
  savePipeline(profile: PipelineProfile, toCache: boolean = true): Observable<PipelineProfile> {
    if (!toCache) {
      return super.basePost<PipelineProfile, PipelineProfile>(Endpoint.PIPELINE, this.PREFIX, profile);
    } else {
      return new Observable(observer => {
        // TEMP :: Convert for API model, which uses datatype instead of dataType in filter
        const converted: any = this.convertPipelinePost(profile);
        super.basePost<any, PipelineProfile>(Endpoint.PIPELINE, this.PREFIX, converted).subscribe({
          next: (result: PipelineProfile) => {
            profile.id = result.id;
            this.addToStore(profile).subscribe({
              next: () => {
                observer.next(profile);
                observer.complete();
              },
              error: err => observer.error(err)
            });
          },
          error: err => observer.error(err)
        });
      });
    }
  }

  /**
   * Save pipeline profile edition and add to cache
   */
  savePipelineEdition(profile: PipelineProfile, toCache: boolean = true): Observable<PipelineProfile> {
    const url = `${this.PREFIX}/${profile.id}`;
    if (!toCache) {
      return super.basePut<PipelineProfile, PipelineProfile>(Endpoint.PIPELINE, url, profile);
    } else {
      return new Observable(observer => {
        // TEMP :: Convert for API model, which uses datatype instead of dataType in filter
        const converted: any = this.convertPipelinePost(profile);
        super.basePut<any, PipelineProfile>(Endpoint.PIPELINE, url, converted).subscribe({
          next: () => {
            this.addToStore(profile).subscribe({
              next: () => {
                observer.next(profile);
                observer.complete();
              },
              error: err => observer.error(err)
            });
          },
          error: err => observer.error(err)
        });
      });
    }
  }

  /**
   * Delete pipeline
   */
  deletePipeline(taskId: string): Observable<PipelineProfile> {
    const url = `DataPipelines/${taskId}`;
    return super.baseDelete(Endpoint.PIPELINE, url);
  }

  /**
   * Start pipeline/start step function
   */
  startPipeline(id: string): Observable<RunResult> {
    const url = `${this.PREFIX}/${id}/start`;
    return super.basePost<{ id: string }, RunResult>(Endpoint.PIPELINE, url, { id });
  }

  /**
   * Get pipeline profile by id from backend
   */
  getProfileById(id: string): Observable<PipelineProfile> {
    const url = `${this.PREFIX}/${id}`;
    return super.baseGet<PipelineProfile>(Endpoint.PIPELINE, url).pipe(map(result => this.convertPipelineGet(result)));
  }

  /**
   * Get pipeline profiles from backend
   */
  getProfiles(): Observable<PipelineProfile[]> {
    return super.baseGet<{ DataPipelines: PipelineProfile[] }>(Endpoint.PIPELINE, this.PREFIX, true).pipe(
      map((profiles: { DataPipelines: PipelineProfile[] }) => {
        if (profiles && profiles.DataPipelines) {
          return profiles.DataPipelines.sort((a: any, b: any) => (a.updatedAt > b.updatedAt ? -1 : 1)).map(profile =>
            this.convertPipelineGet(profile)
          );
        } else {
          const message = profiles
            ? 'Returned data did not have an PipelineProfiles property'
            : 'Returned data was null';
          console.warn(message);
          return [];
        }
      })
    );
  }

  /**
   * Get map of profiles
   */
  getProfileStore(fromCache: boolean = true): Observable<Map<string, PipelineProfile>> {
    // check local store for profiles
    if (fromCache) {
      const store: Map<string, PipelineProfile> = this.storeService.get(this.PIPELINE_STORE);
      if (store) {
        return observableOf(store);
      }
    }
    // for a new load and update store
    return this.getProfilesMap().pipe(
      map((mp: Map<string, PipelineProfile>) => {
        this.storeService.set(this.PIPELINE_STORE, mp);
        return mp;
      })
    );
  }

  /**
   * Get array of profiles associated with the given table id
   */
  getProfilesByTable(tableId: string, fromCache: boolean = true): Observable<PipelineProfile[]> {
    return this.getProfileStore(fromCache).pipe(
      map((result: Map<string, PipelineProfile>) => {
        const profiles: PipelineProfile[] = [];
        result.forEach((value: PipelineProfile) => {
          if (value.destination.destinationId === tableId) {
            profiles.push(value);
          }
        });
        return profiles;
      })
    );
  }

  private getProfilesMap(): Observable<Map<string, PipelineProfile> | any> {
    return this.getProfiles().pipe(
      map((result: PipelineProfile[]) => {
        const m = new Map<string, PipelineProfile>();
        for (const profile of result) {
          m.set(profile.id as string, profile);
        }
        return m;
      }),
      catchError(err => err)
    );
  }

  /**
   * Method to add profile to local profile store
   */
  public addToStore(profile: PipelineProfile): Observable<PipelineProfile> {
    if (profile) {
      return this.getProfileStore(true).pipe(
        map((mp: Map<string, PipelineProfile>) => {
          if (mp) {
            mp.set(profile.id as string, profile);
            return profile;
          } else {
            throw new Error('Store not found');
          }
        })
      );
    } else {
      return throwError(() => new Error('PipelineProfile was null'));
    }
  }

  /**
   * Convert PipelineProfile to API compatible version
   */
  private convertPipelinePost(profile: PipelineProfile): PipelineProfile {
    const converted: PipelineProfile = cloneDeep(profile);
    converted.source.fields = converted.source.fields.map(field => {
      let dataType: DataType | undefined;
      if (field.filter) {
        dataType = field.filter.dataType || (<any>field.filter).datatype;
      }
      if (!dataType) {
        dataType = field.dataType || DataType.UNKNOWN;
      }
      if (dataType === 'timestamp' || dataType === 'date' || dataType === 'datetime' || dataType === 'time') {
        return {
          name: field.name,
          included: field.included,
          dataType,
          filter: {
            dataType,
            value: field.filter ? field.filter.value : null,
            operator: field.filter ? field.filter.operator : null,
            dateValue: {
              dataType: 'string',
              quantity: field.filter ? field.filter.dateValue?.quantity : null,
              interval: field.filter ? field.filter.dateValue?.interval : ''
            }
          }
        };
      } else {
        return {
          name: field.name,
          included: field.included,
          dataType,
          filter: {
            dataType,
            value: field.filter ? field.filter.value : null,
            operator: field.filter ? field.filter.operator : null
          }
        };
      }
    }) as ImportSourceField[];
    return converted;
  }

  /**
   * Convert PipelineProfile from API
   */
  private convertPipelineGet(profile: any): PipelineProfile {
    const converted: PipelineProfile = cloneDeep(profile);
    if (Array.isArray(converted.source.fields)) {
      converted.source.fields =
        converted.source.fields &&
        converted.source.fields.map(field => {
          let dataType: DataType | undefined;
          if (field.filter) {
            dataType = field.filter.dataType || (<any>field.filter).datatype;
          }
          if (!dataType) {
            console.warn('DataType not defined or found in filter ', field.name);
            dataType = field.dataType || DataType.UNKNOWN;
          }
          return <ImportSourceField>{
            name: field.name,
            included: field.included,
            dataType,
            filter: field.filter
              ? {
                  dataType,
                  value: field.filter.value,
                  operator: field.filter.operator
                }
              : {}
          };
        });
    } else {
      converted.source.fields = [];
    }

    return converted;
  }

  /**
   * Get Imports from API
   */
  getImports(
    pageSize: number,
    sort: string = 'desc',
    lastKeyAsGUID: string | undefined = undefined,
    destinationTable: string | undefined = undefined,
    name: string | undefined = undefined
  ): Observable<ImportPaginationData> {
    const params = {
      withTasks: true,
      pageSize,
      sort,
      ...(lastKeyAsGUID && { lastKeyAsGUID }),
      ...(destinationTable && { destinationTable }),
      ...(name && { name })
    };
    return super.baseGet(Endpoint.NXTDRIVE, `data-pipelines`, false, params);
  }
}
