import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { BACKSPACE, LEFT_ARROW, OPEN_SQUARE_BRACKET, RIGHT_ARROW } from '@angular/cdk/keycodes';

// Notes from how to implement this :
// https://itnext.io/creating-a-custom-form-field-control-compatible-with-reactive-forms-and-angular-material-cf195905b451
// https://v9.material.angular.io/guide/creating-a-custom-form-field-control
@Component({
  selector: 'autocomplete-syntax-input',
  templateUrl: './autocomplete-syntax-input.component.html',
  styleUrls: ['./autocomplete-syntax-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteSyntaxInputComponent
    }
  ],
  // eslint-disable-next-line
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy'
  }
})
export class AutocompleteSyntaxInputComponent
  implements DoCheck, OnDestroy, ControlValueAccessor, MatFormFieldControl<string> {
  static nextId = 0;

  // Start AutocompleteSyntaxInputComponent Specific properties
  @Input() classes: string = '';
  @Input() syntaxStartKey: string = '{{';
  @Input() syntaxEndKey: string = '}}';
  @Input() set options(options: string[]) {
    this._allOptions = options;
    this._options = options;
  }
  @Input() set optionsHints(optionsHints: string[]) {
    this._allOptionsHints = optionsHints;
    this._optionsHints = optionsHints;
  }
  @Input() autocompletePanelWidth: string;

  @ViewChild('syntaxInput') syntaxInput: ElementRef;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) trigger: MatAutocompleteTrigger;

  public autoCompleteDisabled: boolean = true;
  private syntaxBuffer: string = '';
  private selectedOption: boolean = false;

  public _allOptions: string[] = [];
  public _options: string[] = [];
  public _allOptionsHints: string[] = [];
  public _optionsHints: string[] = [];
  // End AutocompleteSyntaxInputComponent Specific properties

  @HostBinding() id = `autocomplete-syntax-input-${AutocompleteSyntaxInputComponent.nextId++}`;

  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  /**
   * Gets called when the editor's value has changed
   *
   * @type {EventEmitter<string>} The editor's new value
   */
  @Output() textChanged = new EventEmitter<string>();

  _value: string;

  _changeFn: (_: any) => void;
  _touchedFn: any;

  // MatFormFieldControl Implementation

  stateChanges = new Subject<void>();
  focused;
  controlType = 'autocomplete-syntax-input';
  errorState = false;
  public _placeholder: string;
  public _required = false;
  public _disabled = false;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(dis) {
    this._disabled = coerceBooleanProperty(dis);
    this.stateChanges.next();
  }

  constructor(
    public _elementRef: ElementRef,
    public _focusMonitor: FocusMonitor,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef.nativeElement, true).subscribe(origin => {
      if (this.focused && !origin) {
        this._touchedFn();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // Start AutocompleteSyntaxInputComponent Specific functions

  private get startToken() {
    return this.syntaxStartKey[0];
  }
  private get endToken() {
    return this.syntaxEndKey[0];
  }

  findToken(string: string, direction: 'left' | 'right', count: number, token: string) {
    if (count > string.length) {
      return -1;
    }
    if (direction === 'left') {
      if (string[string.length - count] === token) {
        return count;
      } else {
        return this.findToken(string, direction, ++count, token);
      }
    } else {
      if (string[count] === token) {
        return count;
      } else {
        return this.findToken(string, direction, ++count, token);
      }
    }
  }

  onClick(e) {
    const start = this.syntaxInput.nativeElement.selectionStart;
    const open = this.findToken(this.value.substr(0, start), 'left', 0, this.startToken);
    const close = this.findToken(this.value.substr(0, start), 'left', 0, this.endToken);

    if (open >= 0 && (close === -1 || open < close)) {
      const countRight = this.findToken(this.value.substr(start, this.value.length), 'right', 0, this.syntaxEndKey[0]);
      if (countRight) {
        e.preventDefault();
        this.syntaxInput.nativeElement.setSelectionRange(start - open - 1, start + countRight + 2);
      }
    }
  }

  onInput(e: any) {
    if (this.autoCompleteDisabled) {
      this.syntaxBuffer = e.target.value;
    }
  }

  onKeyDown(e: KeyboardEvent) {
    const keyCode = e.keyCode;
    if (this.autoCompleteDisabled) {
      const start = this.syntaxInput.nativeElement.selectionStart;
      const end = this.syntaxInput.nativeElement.selectionEnd;

      if ([LEFT_ARROW, RIGHT_ARROW, BACKSPACE].indexOf(keyCode) >= 0 && start === end) {
        if ([LEFT_ARROW, BACKSPACE].indexOf(keyCode) >= 0 && this.value[start - 1] === this.endToken) {
          e.preventDefault();
          const count = this.findToken(this.value.substr(0, start - 2), 'left', 0, this.startToken);
          this.syntaxInput.nativeElement.setSelectionRange(start - (count + 3), end);
        } else if (
          keyCode === RIGHT_ARROW &&
          this.value[start] === this.startToken &&
          this.value[start + 1] === this.startToken
        ) {
          e.preventDefault();
          const count = this.findToken(this.value.substr(start + 2, this.value.length), 'right', 0, this.endToken);
          this.syntaxInput.nativeElement.setSelectionRange(end, start + (count + 4));
        }
      }
    } else if ([LEFT_ARROW, RIGHT_ARROW].indexOf(keyCode) >= 0) {
      e.preventDefault();
    }
  }

  onKeyUp(e: KeyboardEvent) {
    const keyCode = e.keyCode;
    if (this.autoCompleteDisabled) {
      const start = this.syntaxInput.nativeElement.selectionStart;

      if (
        keyCode === OPEN_SQUARE_BRACKET &&
        this.value[start - 1] === this.startToken &&
        this.value[start - 2] === this.startToken
      ) {
        this.autoCompleteDisabled = false;
        this.syntaxBuffer = `${this.value.substr(0, start)}:${this.value.substr(start, this.value.length)}`;
        this.trigger.openPanel();
        return;
      }
    }

    this._options = this._filterOptions(this.value);
    this._optionsHints = this._allOptionsHints.filter((h, i) => this._options.indexOf(this._allOptions[i]) >= 0);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value =
      this.syntaxBuffer.split(':')[0] + event.option.value + this.syntaxEndKey + this.syntaxBuffer.split(':')[1];
    this.value = value;
    this.syntaxBuffer = value;
    this.selectedOption = true;
    this.autoCompleteDisabled = true;
  }

  onAutocompleteClosed() {
    setTimeout(() => {
      if (!this.selectedOption) {
        this.syntaxBuffer = this.syntaxBuffer.replace(`${this.syntaxStartKey}:`, '');
        this.value = this.syntaxBuffer;
      }
      this.autoCompleteDisabled = true;
      this.selectedOption = false;
    });
  }

  private _filterOptions(value: string): string[] {
    if (this.syntaxInput) {
      const carret = this.syntaxInput.nativeElement.selectionStart;
      const count = this.findToken(this.value.substr(0, carret), 'left', 0, this.startToken);
      const filterValue = this.value.substr(carret - count + 1, count - 1).toLocaleLowerCase();

      return this._allOptions.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return [];
    }
  }

  // End AutocompleteSyntaxInputComponent Specific functions

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState =
        (this.ngControl.invalid as boolean) && ((this.ngControl.touched || this.ngControl.dirty) as boolean);
      this.stateChanges.next();
    }
  }

  onContainerClick(event: MouseEvent) {}

  get empty() {
    const commentText = this._value ? this._value.trim() : '';
    return commentText ? false : true;
  }

  // ControlValueAccessor implementation

  registerOnChange(fn: (_: any) => void): void {
    this._changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this._touchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  // Event handling

  onChange() {
    if (this._changeFn) {
      this._changeFn(this._value);
    }

    this.textChanged.emit(this._value);
  }
  // Property accessors

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.onChange();
    this.stateChanges.next();
  }
}
